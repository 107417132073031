import { createSlice,createAsyncThunk, current} from '@reduxjs/toolkit'
import produce from '@reduxjs/toolkit/node_modules/immer'
import {get} from '../../../api/MainClient'
//get course
export const getCourseList=createAsyncThunk(
    "courses/getCourseList",
    async (page)=>{
        const resp=await get(`/new-course-list/?page=${page?.page}&ordering=${page?.sorting}`)
        return resp.data
    }
)
// send filter data
export const postFilterData=createAsyncThunk(
    "courses/postFilterData",
    async (dataType,{getState})=>{
        const cors=await getState()
let categoryList=[]
        const activeType=cors.courses.type?.find(item=>item.checked===true)
        const categoryArray=  cors.courses.categories?.map(arr=>{
            if(arr.checked==true){
              categoryList.push(arr.id)
            }
          })
       const categoryString=categoryList?.toString()
       let languageList=[]
        const languageArray=cors.courses.languages?.map(arr=>{
            if(arr.checked==true){
              languageList.push(arr.id)
            }
          })
        const languageString=languageList?.toString()
          let levelList=[]
          const levelsArray=cors.courses.levels?.map(arr=>{
            if(arr.checked==true){
              levelList.push(arr.id)
            }
          })
        const levelString=levelList?.toString()
        const activeDuration=cors.courses.duration?.find(item=>item.checked===true)
        const activePrice=cors.courses.price?.find(item=>item.checked===true)
        // console.log(`course/?type=${activeType&&activeType.id!==0?activeType.id:''}&category=${categoryString}&instructor=&price__gte=${activePrice!==undefined?activePrice.priceStart:''}&price__lte=${activePrice!==undefined?activePrice.priceEnd:''}&level=${levelString}&duration__lte=${activeDuration!==undefined?activeDuration.end:''}&duration__gte=${activeDuration!==undefined?activeDuration.start:''}&language=${languageString}`)
        const resp=await get(`/new-course-list/?type=${activeType&&activeType.id!==0?activeType.id:''}&category=${categoryString}&price_min=${activePrice!==undefined?activePrice.priceStart:''}&price_max=${activePrice!==undefined?activePrice.priceEnd:''}&level=${levelString}&duration_min=${activeDuration!==undefined?activeDuration.start:''}&duration_max=${activeDuration!==undefined?activeDuration.end:''}&language=${languageString}&ordering=${cors?.courses.listOrder==="POPULAR"?'popular':`${cors?.courses.listOrder}`}&page=${cors?.courses?.page}`)
        return resp.data
    }
)

export const filterAppliedJumpToPage=createAsyncThunk(
    'courses/filterAppliedJumpToPage',
    async (pageNo,{getState})=>{
        const currentState=await getState().courses
     const appliedUrl=currentState.prev?.split('api/').at(-1)||currentState.next?.split('api/').at(-1)
     const replacedUrlPageNo=appliedUrl.split('&page=')[1].at(0)
     const replacedUrl=appliedUrl.replace(`&page=${replacedUrlPageNo}`,`&page=${pageNo}`)
     const response=await get(replacedUrl)
     return response.data
    }
)

export const RedirectToPageNo=createAsyncThunk(
    'courses/RedirectToPageNo',
    async (path)=>{
    const SplitUrl=path.split('api/')
        const resp =await get(SplitUrl.at(-1))
        return resp.data
    }
)

// get new to old
export const courseNewToOld=createAsyncThunk(
    "courses/courseNewToOld",
    async ()=>{
        const resp=await get('course/?ordering=-created_at')
        return resp.data
    }
)

// get old to new
export const courseOldToNew=createAsyncThunk(
    "courses/courseOldToNew",
    async ()=>{
        const resp=await get(`course/?ordering=created_at`)
        return resp.data
    }
)

// get course types 
export const getCourseTypes=createAsyncThunk(
    "courses/getCourseTypes",
    async ()=>{
        const resp=await get('course-type/')
        return resp.data
    }
)
//get course categories
export const getCourseCategories=createAsyncThunk(
    "courses/getCourseCategories",
    async ()=>{
        const resp=await get('course-category/')
        return resp.data
    }
)
//get course languages
export const getCourseLanguages=createAsyncThunk(
    "courses/getCourseLanguages",
    async ()=>{
        const resp=await get('course-language/')
        return resp.data
    }
)
//get course levels
export const getCourseLevels=createAsyncThunk(
    "courses/getCourseLevels",
    async ()=>{
        const resp=await get('course-level/')
        return resp.data
    }
)
const initialState ={
    count:1,
    status:true,
    showBan:true,
    courseList:[],
    filterTypes:[],
    type:[],
    categories:[],
    languages:[],
    levels:[],
    page:1,
    duration:[
        {start:0,end:60,checked:false,id:1},
        {start:60,end:120,checked:false,id:2},
        {start:120,end:240,checked:false,id:3},
        {start:300,end:'',checked:false,id:4},
    ],
    price:[
        {priceStart:100,priceEnd:500,checked:false},
        {priceStart:500,priceEnd:1000,checked:false},
        {priceStart:1000,priceEnd:5000,checked:false},
        {priceStart:5000,priceEnd:'',checked:false},
    ],
  listOrder:"POPULAR"
}
const courseSlice = createSlice({
    name: 'courses',
    initialState,
   reducers:{
    editFilterData:(state,{payload})=>{
        const {keyName,value}=payload
        if(keyName==="listOrder"){
            return {...state,[keyName]:value}
          }
          else{
        const nextstate=produce(current(state[keyName]),draft=>{
             if(payload.keyName==="duration"||payload.keyName==="price"||payload.keyName==="type"){
                current(state[keyName]).map((item,i)=>{
                    if(i!==payload.index||item.checked===true){
                       draft[i].checked=false
                    }
                    else{
                        draft[i].checked=true
                    }
                })
            }
            else{
            if(draft[payload.index].checked)
            draft[payload.index].checked=false
            else
            draft[payload.index].checked=true
            }
        })
      return {...state,[keyName]:nextstate}
      }
    },
    deleteFilterData:(state,{payload})=>{
        const {keyName,value}=payload
        const nextstate=produce(current(state[keyName]),draft=>{
            draft[payload.index].checked=false
        })
      return {...state,[keyName]:nextstate}
    },
  getAppliedFilter:(state,{payload})=>{
    const currentState=current(state)
  const currentData={type:currentState.type,categories:currentState.categories,languages:currentState.languages,
    levels:currentState.levels,duration:currentState.duration,price:currentState.price}
    let fillArr=[]
          for (let value in currentData) {
        currentData[value].map((item,i)=>{
            if(item.checked===true){
                fillArr.push({...item,index:i,keyName:value})
            }
        }) }
        return {...state,filterTypes:fillArr}
  },
  clearFilter:(state,action)=>{
      return {...state,filterTypes:[],
        duration:[
            {start:0,end:60,checked:false,id:1},
            {start:60,end:120,checked:false,id:2},
            {start:120,end:240,checked:false,id:3},
            {start:240,end:'',checked:false,id:4},
            
    
        ],
        price:[
            {priceStart:100,priceEnd:500,checked:false},
            {priceStart:500,priceEnd:1000,checked:false},
            {priceStart:1000,priceEnd:5000,checked:false},
            {priceStart:5000,priceEnd:'',checked:false},
        ]
    }
  },
     changepages:(state,action)=>{
        if(action.payload.type==="INCREMENT"){
        return{...state,page:state.page+1}
        }
        else if(action.payload.type==="DECREMENT"){
        return{...state,page:state.page-1}
        }
        else if(action.payload.type==="SET"){
        return {...state,page:action.payload.value}
        }
     },
     onBannnerClick:(state,action)=>{
        if(action.payload.type === "CLOSE_BNR"){
            return {...state,showBan:false}
        }
        if(action.payload.type === "OPEN_BNR"){
            return {...state,showBan:true}
        }

        
           }
   },



   extraReducers:{
       // get course status
   [getCourseList.pending]:(state,action)=>{
       console.log('pending');
      return {...state,status:true,}
   },
   [getCourseList.fulfilled]:(state,action)=>{
       console.log(action.payload);
    console.log('success');
    if(action.payload.length===0){
        return {...state,courseList:"NO_COURSE"}
        }
        else
       return {...state,status:false,courseList:action.payload.results,count:Math.ceil(action.payload.count/12),prev:action.payload.previous,next:action.payload.next}

   },
   [getCourseList.rejected]:(state,action)=>{
       state.status="failed"
           return {...state,status:false}
   },
       //////////////////////////////////

    //    get filtered course 
   [postFilterData.pending]:(state,{payload})=>{
       console.log("filter pending");
   },
   [postFilterData.fulfilled]:(state,{payload})=>{
    console.log("filter success");
    if(payload.results.length===0){
    return {...state,courseList:"NO_COURSE"}
    }
    else
    // return {...state,courseList:payload.results}
    return {...state,status:false,courseList:payload.results,count:Math.ceil(payload.count/12),prev:payload.previous,next:payload.next}
   },
   [postFilterData.rejected]:(state,{payload})=>{
    console.log("filter rejected");
    return {...state}
},
////////////////////////////////////
// sort by filters 
   [courseNewToOld.fulfilled]:(state,{payload})=>{
       console.log("new to old success");
       return {...state,courseList:payload.results}
   },
   [courseOldToNew.fulfilled]:(state,{payload})=>{
    console.log("old to new success");
    return {...state,courseList:payload.results}
},
//////////////////////////////////////
// set course types
   [getCourseTypes.fulfilled]:(state,{payload})=>{
     const editArr=payload.map((item)=>{
         return {...item,checked:false}
     })
     state.type=[{id:0,course_type:"All",checked:false},...editArr]
   },
//    set course category 
   [getCourseCategories.fulfilled]:(state,{payload})=>{
    const editArr=payload.map((item)=>{
        return {...item,checked:false}
    })
    state.categories=[...editArr]
   },
//    set course languages 
   [getCourseLanguages.fulfilled]:(state,{payload})=>{
    const editArr=payload.map((item)=>{
        return {...item,checked:false}
    })
    state.languages=[...editArr]
   },
//    set course levels 
   [getCourseLevels.fulfilled]:(state,{payload})=>{
    const editArr=payload.map((item)=>{
        return {...item,checked:false}
    })
    state.levels=[...editArr]
   },
   [RedirectToPageNo.fulfilled]:(state,{payload})=>{
    console.log(payload);
    return {...state,status:false,courseList:payload.results,count:Math.ceil(payload.count/12),prev:payload.previous,next:payload.next}
    },
    [filterAppliedJumpToPage.fulfilled]:(state,{payload})=>{
        console.log(payload);
    return {...state,status:false,courseList:payload.results,count:Math.ceil(payload.count/12),prev:payload.previous,next:payload.next}
        }
   }

});


export const getAllcourse=(state)=>state.courses

export const {getAppliedFilter,clearFilter,editFilterData,deleteFilterData,changepages,onBannnerClick} = courseSlice.actions
export default courseSlice.reducer