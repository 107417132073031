import { createAsyncThunk,createSlice } from '@reduxjs/toolkit'
import {get,post,del, put} from '../../../api/MainClient'

export const getAssessmentQuestions=createAsyncThunk(
    "StudentAssessment/StudentAssessment",
    async (id)=>{
        const resp=await get(`/quiz/final-assessment-list/${id}/`)
        return resp.data
    }
)

 const studentAssessmentSlice=createSlice({
name:'StudentAssessment',
initialState:{
    questions:[],
    currentQuestion:0,
    selectedOption:[],
    markReview:[]
    
},
reducers:{
    incrementQuestions:(state,{payload})=>{
        return {...state,currentQuestion:state.currentQuestion+1}
    },
    decrementQuestions:(state,{payload})=>{
        return {...state,currentQuestion:state.currentQuestion-1}
    },
    setQuestion:(state,{payload})=>{
  return {...state,currentQuestion:payload.index}
    },
    addOption:(state,{payload})=>{
        if(state.markReview.find(option=>option.quiz==payload[0].quiz)){
            const copyState=state.markReview.filter(item=>item.quiz!==payload[0].quiz)
            state.markReview=[...copyState]
        }
     const copyState=[...state.selectedOption]
     if(state.selectedOption.find(option=>option.quiz==payload[0].quiz)){
     const copyState=state.selectedOption.filter(item=>item.quiz!==payload[0].quiz)
     state.selectedOption=[...copyState,...payload]
     }
     else{
         state.selectedOption=[...state.selectedOption,...payload]
     }
    },
    addMarkReview:(state,{payload})=>{
        if(state.selectedOption.find(option=>option.quiz==payload[0].quiz)){
            const copyState=state.selectedOption.filter(item=>item.quiz!==payload[0].quiz)
            state.selectedOption=[...copyState]
        }
        
                const copyState=[...state.markReview]
                if(state.markReview.find(option=>option.quiz==payload[0].quiz)){
                state.markReview=[...payload]
                }
                else{
                    state.markReview=[...state.markReview,...payload]
                }
        
            },
    removeMarkReview:(state,{payload})=>{
        console.log(payload);
        const NewState=state.markReview.filter((option)=>option.quiz!==payload)
        state.markReview=NewState
    },
    clearAssessmentStore:(state,{payload})=>{
        return {...state,currentQuestion:0,selectedOption:[],markReview:[]}
    }
        },

 extraReducers:{
     [getAssessmentQuestions.fulfilled]:(state,{payload})=>{
         console.log(payload);
         if(payload.error){
         return {...state,questions:[]}
         }
         else{
            const data=payload.map((item)=>item.quiz)
            return {...state,questions:data.reverse()}
         }
        
     }
 }
})
export const {incrementQuestions,decrementQuestions,setQuestion,addOption,addMarkReview,clearAssessmentStore,removeMarkReview}=studentAssessmentSlice.actions
export const getStudentAssessmentData=(state)=>state.studentAssessment

export default studentAssessmentSlice.reducer