import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, post, del, put } from "../../../api/MainClient";

export const getResourceGuestQuestions = createAsyncThunk(
  "ResourceGuestQuiz/ResourceGuestQuiz",
  async (id) => {
    const resp = await get(`/resources/quiz-guest/${id}/`);
    return resp.data;
  }
);

const resourceQuizSlice = createSlice({
  name: "ResourceGuestQuiz",
  initialState: {
    questions: [],
    currentQuizQuestion: 0,
    selectedOption: [],
    markReview: [],
  },
  reducers: {
    incrementQuestions: (state, { payload }) => {
      return { ...state, currentQuizQuestion: state.currentQuizQuestion + 1 };
    },
    decrementQuestions: (state, { payload }) => {
      return { ...state, currentQuizQuestion: state.currentQuizQuestion - 1 };
    },
    setQuestion: (state, { payload }) => {
      return { ...state, currentQuizQuestion: payload.index };
    },
    addOption: (state, { payload }) => {
      if (state.markReview.find((option) => option.quiz == payload[0].quiz)) {
        const copyState = state.markReview.filter(
          (item) => item.quiz !== payload[0].quiz
        );
        state.markReview = [...copyState];
      }
      const copyState = [...state.selectedOption];
      if (
        state.selectedOption.find((option) => option.quiz == payload[0].quiz)
      ) {
        const copyState = state.selectedOption.filter(
          (item) => item.quiz !== payload[0].quiz
        );
        state.selectedOption = [...copyState, ...payload];
      } else {
        state.selectedOption = [...state.selectedOption, ...payload];
      }
    },
    addMarkReview: (state, { payload }) => {
      if (
        state.selectedOption.find((option) => option.quiz == payload[0].quiz)
      ) {
        const copyState = state.selectedOption.filter(
          (item) => item.quiz !== payload[0].quiz
        );
        state.selectedOption = [...copyState];
      }

      const copyState = [...state.markReview];
      if (state.markReview.find((option) => option.quiz == payload[0].quiz)) {
        state.markReview = [...payload];
      } else {
        state.markReview = [...state.markReview, ...payload];
      }
    },
    removeMarkReview: (state, { payload }) => {
      console.log(payload);
      const NewState = state.markReview.filter(
        (option) => option.quiz !== payload
      );
      state.markReview = NewState;
    },
    clearAssessmentStore: (state, { payload }) => {
      return {
        ...state,
        currentQuizQuestion: 0,
        selectedOption: [],
        markReview: [],
        questions: [],
      };
    },
  },

  extraReducers: {
    [getResourceGuestQuestions.fulfilled]: (state, { payload }) => {
      console.log(payload);
      if (payload.error) {
        return { ...state, questions: [] };
      } else {
        return { ...state, questions: payload.reverse() };
      }
    },
  },
});
export const {
  incrementQuestions,
  decrementQuestions,
  setQuestion,
  addOption,
  addMarkReview,
  clearAssessmentStore,
  removeMarkReview,
} = resourceQuizSlice.actions;
export const getResourceQuizData = (state) => state.resourceQuiz;

export default resourceQuizSlice.reducer;
