/*google globel */
import React, { Suspense, lazy, useState } from "react";
// import Markup from './markup/markup';
import { useEffect } from "react";
import Fingrad from "./images/Fingrad-Logo.png";
// Plugins Stylesheet
import "bootstrap/dist/css/bootstrap.min.css";
import jwt_decode from "jwt-decode";
// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// StyleSheet
import "./css/typography.css";
import "./css/shortcodes/shortcodes.css";
import "./css/style.css";
import "./css/color/color-1.css";

// Fonts
import "./vendors/fontawesome/css/font-awesome.min.css";
import "./vendors/flaticon/flaticon.css";
import "./vendors/line-awesome/css/line-awesome.min.css";
import "./vendors/themify/themify-icons.css";
import ConnectionError from "./markup/pages/ConnectionLose/ConnectionError";
import { useHistory } from "react-router-dom";
import Connelose from "./images/Connectionerror/connection-lost.png";
import { postGoogleData, postOneTapGoogle } from "./api/fetchClient";
import { Affiliateget } from "./Affiliate/Api/AffiliateClient";
import { Activeplan } from './api/fetchClient';
// import OneTapSignins from './markup/pages/OneTapSignin/OneTapSignin';

const Markup = lazy(() => import("./markup/markup"));


function App() {
  const userData = JSON.parse(window.localStorage.getItem("user_data"));
  const bannerStart = window.localStorage.getItem('bannerStart')
  const today = new Date()
  console.log(today,"enddate")
  useEffect(() => {
    const getDateInfo = ()=>{
      const _today = new Date()
      const endDate = new Date()
      endDate.setDate(_today.getDate()+2)
      const date = new Date(bannerStart)
      if (!bannerStart){
        window.localStorage.setItem('bannerStart',_today)
        window.localStorage.setItem('bannerEnd',endDate)
      }
      else if((_today - new Date(bannerStart))/(1000*3600)>47){
        console.log((_today - new Date(bannerStart))/(1000*3600)>48,"appppp ")
        window.localStorage.setItem('bannerStart',_today)
        window.localStorage.setItem('bannerEnd',endDate) 
      }
    }
    const getdata = async () => {
      const userdata = await Affiliateget().then((resp) => {
        console.log(resp);

        localStorage.setItem(
          "Affiliate",
          resp?.data || resp?.data !== undefined
            ? JSON.stringify(resp?.data)
            : JSON.stringify("")
        );
      });
      const activePlan = await Activeplan().then((res) => {
        console.log(res?.data, "ACTIVE PLAN INDEX JSX");
        if (userData) {
          window.localStorage.setItem(
            "Subscription",
            res?.data || res?.data !== undefined
              ? JSON.stringify(res?.data)
              : JSON.stringify("")
          );
        }
      });
    };
    getdata();
    getDateInfo();
  }, []);
  // const subStatus= window.localStorage.getItem("Subscription")
  // if(subStatus!==undefined ){
  // 	window.localStorage.setItem(
  //         "Subscription",
  //         JSON.stringify("")
  //       );
  // }
  // const [isSignedIn, setIsSignedIn] = useState(false);
  // const [userInfo, setUserInfo] = useState(null)
  // console.log("isSignedIn");
  // const onOneTapSignedIn =async response => {
  //   setIsSignedIn(true)
  //   const decodedToken = jwt_decode(response.credential)
  //   console.log('TOKEN :'+ response.credential)
  //   console.log("",response)
  //   console.log(decodedToken);
  //   setUserInfo({...decodedToken})
  //   const data={
  //     id_token :response.credential
  //   }
  //   const postGoogle = await postOneTapGoogle(data).then((res)=>{
  // 	if (res.status === 400) {
  // 		console.log("status is 400")
  // 		window.localStorage.clear();
  // 	  }else if (res.status === 200){
  // 		console.log(res)
  // 		localStorage.setItem("user_data", JSON.stringify(res.data));
  // 	    window.location.reload();
  // 	  }

  //   })

  // }
  // const userString = localStorage.getItem("user_data");
  // const user = JSON.parse(userString);

  // const initializeGSI = () => {
  // 	!user?.access_token &&
  // 	window.google.accounts.id.initialize({
  // 	// client_id: '1037629732575-0uod8qtmqp5ovu9msht2esb23cfc0qt5.apps.googleusercontent.com',
  // 	client_id:process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID,
  // 	cancel_on_tap_outside: false,
  // 	callback: onOneTapSignedIn
  //   });
  //   window.google.accounts.id.prompt((notification) => {
  // 	if (notification.isNotDisplayed()) {
  // 	  console.log(notification.getNotDisplayedReason())
  // 	} else if (notification.isSkippedMoment()) {
  // 	  console.log(notification.getSkippedReason())
  // 	} else if(notification.isDismissedMoment()) {
  // 	  console.log(notification.getDismissedReason())
  // 	}
  //   });
  // }
  // const history = useHistory()
  // const signout = () => {
  //   // refresh the page
  //   window.location.reload();
  // }

  // const googleresp=async(response)=>{}

  // 	useGoogleOneTapLogin({
  // 		onSuccess:(response) =>console.log(response),
  // 		onError:(response)=>console.log(response),
  // 		googleAccountConfigs:{client_id:"618826721867-ritj6a75gbeggaalfbuq142aka35pjdt.apps.googleusercontent.com",}
  // 	})
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);

    useEffect(()=>{
      const Affiliate_code = JSON.parse(
        window.localStorage.getItem("AffiliateCode")
      );
      console.log(Affiliate_code);
      if (!Affiliate_code || Affiliate_code === "") {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const uid = urlParams.get("refer");
        console.log(uid);
        localStorage.setItem(
          "AffiliateCode",
          uid ? JSON.stringify(uid) : JSON.stringify("")
        );
      }
    },[])


  const ConnErr = () => {
    window.location.reload(false);
  };
  // useEffect(() => {
  // 	const el = document.createElement('script')
  // 	el.setAttribute('src', 'https://accounts.google.com/gsi/client')
  // 	el.onload = () => initializeGSI();
  // 	document.querySelector('body').appendChild(el)
  //   }, [])

  const breakpoint = 670;
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, [width]);
  useEffect(() => {
    sessionStorage.setItem('Mailchimp',true)
  }, []);
  console.log("WINDOW SIZE", width);
  useEffect(() => {
    sessionStorage.setItem('Mailchimp',true)
  }, []);
  return (
    <div className="page-wraper">
      {window.navigator.onLine ? (
        <Suspense
          fallback={
            <div
              style={{ height: "100vh" }}
              className="d-flex justify-content-center align-items-center flex-direction-column"
            >
              <div className="d-flex justify-content-center align-items-center flex-column">
                <img
                  src={Fingrad}
                  style={{ width: "60%", height: "60%" }}
                  alt=""
                ></img>
                {/* <h1  style={{color:"#007A78",textAlign:"center"}}>Fingrad</h1> */}
                <i
                  style={{
                    color: "#007A78",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                  className="fas fa-duotone fa-spinner-third fa-4x fa-spin mt-5 "
                ></i>
              </div>
              <div></div>
            </div>
          }
        >
          <Markup />
        </Suspense>
      ) : (
        <div>
          <div className="Connection-Main-Div">
            <img src={Connelose}></img>
            <h1>Connection Lost</h1>
            <p onClick={ConnErr}> Retry again</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
