import { createSlice } from '@reduxjs/toolkit'

export const sidebarSlice = createSlice({
  name: 'sidebarState',
  initialState: {
   activeState:'Dashboard'
  },
  reducers: {
   addActiveState:(state,{payload})=>{
       state.activeState=payload.type
   }
  },
})

// Action creators are generated for each case reducer function
export const {addActiveState} = sidebarSlice.actions
export const getSidebarStore=(state)=>state.sidebarState
export default sidebarSlice.reducer