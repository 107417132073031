import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { del, get, post } from "../../../api/MainClient";
export const getCartAPI = createAsyncThunk("cart/getCartAPI", async () => {
  const response = await get("cart/");
  return response.data;
});

export const getCartListAPI = createAsyncThunk(
  "cart/getCartListAPI",
  async () => {
    const response = await get("/cart/cart-view");
    console.log(response.data, "api called cart");
    return response.data;
  }
);

export const getWebinarCartAPI = createAsyncThunk(
  "cart/getWebinarCartAPI",
  async () => {
    const response = await get("cart/webinar-list/");
    return response.data;
  }
);
// get course save later api
export const getSaveLaterAPI = createAsyncThunk(
  "cart/getSaveLaterAPI",
  async () => {
    const resp = await get("savelater/list/");
    return resp.data;
  }
);
// get webinar save later
export const getWebinarSaveLaterAPI = createAsyncThunk(
  "cart/getWebinarSaveLaterAPI",
  async () => {
    const resp = await get("cart/webinar-savelater-list/");
    return resp.data;
  }
);
// add course to cart

export const addTocartAPI = createAsyncThunk(
  "cart/addTocartAPI",
  async (data, thunkAPI) => {
    const resp = await post("/cart/add/", data).then((resp) => {
      thunkAPI.dispatch(getCartAPI());
      return resp.data;
    });
  }
);
// add webinar to cart
export const addWebinarTocartAPIThunk = createAsyncThunk(
  "cart/addWebinarTocartAPI",
  async (data, thunkAPI) => {
    const resp = await post("/cart/webinar-add/", data).then((resp) => {
      thunkAPI.dispatch(getWebinarCartAPI());
      return resp.data;
    });
  }
);

// delete course from cart
export const deleteCourseFromCartThunk = createAsyncThunk(
  "cart/deleteCourseFromCartThunk",
  async (id, thunkAPI) => {
    const resp = await del(`cart/delete/${id}/`).then((resp) => {
      thunkAPI
        .dispatch(getCartAPI())
        .then(() => thunkAPI.dispatch(cartSlice.actions.mergeCartItems()));
      return resp.data;
    });
  }
);
//delete webinar from cart
export const deleteWebinarFromCartThunk = createAsyncThunk(
  "cart/deleteWebinarFromCartThunk",
  async (id, thunkAPI) => {
    const resp = await del(`/cart/webinar-delete/${id}/`).then((resp) => {
      thunkAPI
        .dispatch(getWebinarCartAPI())
        .then(() => thunkAPI.dispatch(cartSlice.actions.mergeCartItems()));
      return resp.data;
    });
  }
);

// add course to save later
export const addCourseToSaveLaterAPI = createAsyncThunk(
  "cart/addCourseToSaveLaterAPI",
  async (data, thunkAPI) => {
    const resp = await post("savelater/move/", data).then((resp) => {
      thunkAPI
        .dispatch(getCartAPI())
        .then(() =>
          thunkAPI
            .dispatch(getSaveLaterAPI())
            .then(() => thunkAPI.dispatch(cartSlice.actions.mergeCartItems()))
        );
      return resp.data;
    });
  }
);
// add webinar to save later
export const addWebinarToSaveLaterAPI = createAsyncThunk(
  "cart/addWebinarToSaveLaterAPI",
  async (data, thunkAPI) => {
    const resp = await post("/savelater/webinar-move/", data).then((resp) => {
      thunkAPI
        .dispatch(getWebinarCartAPI())
        .then(() =>
          thunkAPI
            .dispatch(getWebinarSaveLaterAPI())
            .then(() => thunkAPI.dispatch(cartSlice.actions.mergeCartItems()))
        );
      return resp.data;
    });
  }
);
//delete course from save later
export const deleteCourseFromSaveLater = createAsyncThunk(
  "cart/deleteCourseFromSaveLAter",
  async (id, thunkAPI) => {
    const resp = await del(`/savelater/remove/${id}/`).then((resp) => {
      thunkAPI
        .dispatch(getSaveLaterAPI())
        .then(() => thunkAPI.dispatch(cartSlice.actions.mergeCartItems()));
      return resp.data;
    });
  }
);
// delete webinar from save later
export const deleteWebinarFromSaveLAter = createAsyncThunk(
  "cart/deleteWebinarFromSaveLAter",
  async (id, thunkAPI) => {
    const resp = await del(`/savelater/remove-webinar/${id}/`).then((resp) => {
      thunkAPI
        .dispatch(getWebinarSaveLaterAPI())
        .then(() => thunkAPI.dispatch(cartSlice.actions.mergeCartItems()));
      return resp.data;
    });
  }
);
// move course to cart from save later
export const moveTocartFromSaveLaterAPI = createAsyncThunk(
  "cart/moveTocartFromSaveLaterAPI",
  async (data, thunkAPI) => {
    const resp = await post("/cart/add/", data).then((resp) => {
      thunkAPI
        .dispatch(getCartAPI())
        .then(() =>
          thunkAPI
            .dispatch(getSaveLaterAPI())
            .then(() => thunkAPI.dispatch(cartSlice.actions.mergeCartItems()))
        );
      return resp.data;
    });
  }
);

// move webinar to cat from save saveLater
export const moveWebinarTocartFromSaveLater = createAsyncThunk(
  "cart/moveWebinarTocartFromSaveLater",
  async (data, thunkAPI) => {
    const resp = await post("/cart/webinar-add/", data).then((resp) => {
      thunkAPI
        .dispatch(getWebinarCartAPI())
        .then(() =>
          thunkAPI
            .dispatch(getWebinarSaveLaterAPI())
            .then(() => thunkAPI.dispatch(cartSlice.actions.mergeCartItems()))
        );
      return resp.data;
    });
  }
);

// move webinar to cat from save saveLater NEW
export const moveWebinarTocartFromSaveLaterNew = createAsyncThunk(
  "cart/moveWebinarTocartFromSaveLaterNew",
  async (data, thunkAPI) => {
    const resp = await post("/cart/item-add", data).then((resp) => {
      thunkAPI
        .dispatch(getCartListAPI())
        .then(() =>
          thunkAPI.dispatch(cartSlice.actions.mainCartItems(resp.data))
        );

      console.log(resp.data, "api called 22222");
      return resp.data;
    });
  }
);
export const deleteItemsFromCart = createAsyncThunk(
  "cart/deleteItemsFromCart",
  async (data, thunkAPI) => {
    console.log(data, "delete api data");
    const resp = await del(`/cart/item-delete/?item_id=${data?.item_id}&item_type=${data?.item_type}`).then((resp) => {
      console.log(resp, "delete api cart response");
      thunkAPI.dispatch(getCartListAPI());

      return resp.data;
    });
  }
);

const initialState = {
  status: true,
  cartList: [],
  mainCartList: [],
  webinarCartList: [],
  mergeCartList: [],
  saveLaterList: [],
  webinarSaveLater: [],
  mergeSaveLaterList: [],
  totalCartAmount: 0,
  totalWebinarAmount: 0,
  alertType: "NONE",
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    mergeCartItems: (state, action) => {
      state.mergeCartList = [...state.cartList, ...state.webinarCartList];
      state.mergeSaveLaterList = [
        ...state.saveLaterList,
        ...state.webinarSaveLater,
      ];
    },

    mainCartItems: (state, action) => {
      state.mainCartList = [...state.mainCartList, action.payload];
    },
    changeAlertType: (state, { payload }) => {
      state.alertType = payload.type;
    },
  },
  extraReducers: {
    // cart API
    [getCartAPI.pending]: (state, { payload }) => {
      return { ...state, status: true, cartList: [] };
    },
    [getCartAPI.fulfilled]: (state, { payload }) => {
      let total = 0;
      payload.map((data) => {
        total += data.course.price;
      });
      return {
        ...state,
        status: false,
        cartList: payload,
        totalCartAmount: total,
      };
      // }
    },
    [getCartAPI.rejected]: (state, { payload }) => {
      return { ...state, status: false, cartList: "EMPTY" };
    },
    // get webinar cart api
    [getWebinarCartAPI.fulfilled]: (state, { payload }) => {
      let total = 0;
      payload.map((data) => {
        total += data.webinar.price;
      });
      // }
      return {
        ...state,
        status: false,
        webinarCartList: payload,
        totalWebinarAmount: total,
      };
    },

    // get  cart api
    [getCartListAPI.fulfilled]: (state, { payload }) => {
      console.log(payload, "payload called");
      let total = 0;

      total += payload.initial_price;

      // }
      return {
        ...state,
        status: false,
        mainCartList: payload,
        total: total,
      };
    },

    // cart API
    // save later API
    [getSaveLaterAPI.pending]: (state, { payload }) => {
      return { ...state, status: true, saveLaterList: [] };
    },
    [getSaveLaterAPI.fulfilled]: (state, { payload }) => {
      return { ...state, status: false, saveLaterList: payload };
    },
    [getSaveLaterAPI.rejected]: (state, { payload }) => {
      return { ...state, status: false, saveLaterList: [] };
    },
    // save later API

    // get webinar save later
    [getWebinarSaveLaterAPI.fulfilled]: (state, { payload }) => {
      return { ...state, status: false, webinarSaveLater: payload };
    },
  },
});

export const { changeAlertType, mergeCartItems, mainCartItems } =
  cartSlice.actions;
export const getAllCart = (state) => state.cart;
export default cartSlice.reducer;
