import ReactDOM from "react-dom";
import belGif from "../../images/notification cute.gif"
import closeBtn from "../../admin/images/icons/close-black.png";
import { useEffect } from 'react';
function NewReleaseModal({ onCloseModal }) {

  useEffect(()=>{
    window.scrollTo(0,0)
  })
  return ReactDOM.createPortal(
    <div className="new-rele-overlay">
      <div className="new-rele-popup-wrap">
        <img
          onClick={onCloseModal}
          src={closeBtn}
          className="new-rele-close-btn"
          alt="close btn"
        />
        <div className="new-rele-top">
          <img src={belGif}></img>
          <h2>Message For You</h2>
        </div>
        <div className="rele-des">
          Dear user, this is to notify you that we are moving to a{" "}
          <span>Subscription Model</span> applicable from{" "}
          <span>4th of July</span> hence all of your currently enrolled courses
          will be locked thereafter. Do not worry, you can subscribe to any of
          our available plans to continue learning and get access to all our
          existing & upcoming courses and webinars for your guided success
          towards financial literacy.
        </div>

        <div className="rele-note-wrap">
          <div className="note">
            <h3>Kind Note</h3>
            <ul>
              <li className="list-item-rele">
                Courses and webinars available on FinGrad will be locked from
                4th July for FREE Users
              </li>
              <li className="list-item-rele">
                You can complete all your pending courses and webinars before
                our migration
              </li>
              <li className="list-item-rele">
                Please download the certificates of the courses that you’ve
                already completed
              </li>
            </ul>
          </div>
        </div>
        <div className="happy-learn">Happy Learning!</div>
      </div>
    </div>,
    document.getElementById("portal")
  );
}

export default NewReleaseModal;
