import React,{Suspense,lazy} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// import reportWebVitals from './reportWebVitals';
// redux persist
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
//  redux 
import store from './app/store'
import { Provider } from 'react-redux'
import { getCartAPI, getCartListAPI, getSaveLaterAPI, getWebinarCartAPI, getWebinarSaveLaterAPI } from './app/reducers/cart/cartSlice';
import { getResourceGuestQuestions } from './app/reducers/Resources/resourceQuizSlice';
//redux data
// const App =lazy(()=>import('./App'))
let persistor = persistStore(store);
if(JSON.parse(localStorage.getItem("user_data"))?.access_token){
store.dispatch(getCartListAPI())
store.dispatch(getWebinarCartAPI())
store.dispatch(getSaveLaterAPI())
store.dispatch(getWebinarSaveLaterAPI())
}else{
  store.dispatch(getResourceGuestQuestions())
}

if (process.env.REACT_APP_BASE_URL === "https://joinfingrad.com/api/") {
  console.log = () => {};
}

ReactDOM.render(
  <React.StrictMode>
  <Provider store={store}>
  <PersistGate loading={
     <div className='d-flex justify-content-center' style={{marginTop:"45vh"}}>
     <i style={{color:'#007A78'}}  className="fas fa-spinner fa-spin fa-5x"></i>
  </div>
  } persistor={persistor}>
    <App />
    </PersistGate>
  </Provider>
  </React.StrictMode>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
