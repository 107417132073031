import { createSlice,createAsyncThunk,current } from '@reduxjs/toolkit'
import {get} from '../../../api/MainClient'
import produce from '@reduxjs/toolkit/node_modules/immer'

const initialState = {

}
export const getCourseContents=createAsyncThunk(
    "courses/getCourseContents",
    async (id)=>{
        const resp=await get(`module-list/?course=${id}`)
        return resp.data
    }
)
export const getModuleCompledStatus=createAsyncThunk(
    "courses/getModuleCompledStatus",
    async (id)=>{
        const resp=await get(`module-completed-status/?course=${id}`)
        return resp.data
    }
)
const courseLearnSlice = createSlice({
  name: 'courseLearn',
  initialState:{
  CourseContent:[],
  currentLesson:{moduleIndex:0,lessonIndex:0},
  activekey: 0
  },
  reducers: {
      currentlessonReducer:(state,{payload})=>{
        return {...state,currentLesson:payload}
      },
      updatevideoCompleted:(state,{payload})=>{
          const currentState=current(state)
        const nextstate=produce(current(state.CourseContent),draft=>{
        draft[currentState.currentLesson.moduleIndex].module_lessons[currentState.currentLesson.lessonIndex].is_completed=true
        })
        return {...state,CourseContent:nextstate}
      },
      accordionactivekey:(state,action)=>{
        if(action.payload===state.activekey){
          return {...state,activekey:null}
        }else
        return {...state,activekey:action.payload}
      }
  },
  extraReducers:{
      [getCourseContents.pending]:(state,action)=>{
         console.log('pending');

      },
      [getCourseContents.fulfilled]:(state,action)=>{
        console.log(action);
        // state.CourseContent=action.payload
        return {...state,CourseContent:action.payload,currentLesson:{moduleIndex:0,lessonIndex:0}}
     },
     [getModuleCompledStatus.fulfilled]:(state,{payload})=>{
       console.log('module complted',payload);
       return {...state,moduleStatus:payload}
     }
  }
});

export const getCourseLearnStore=(state)=>state.courseLearn

export const {currentlessonReducer,updatevideoCompleted,accordionactivekey} = courseLearnSlice.actions

export default courseLearnSlice.reducer