import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { get, post, del, put } from "../../api/MainClient";
import produce from "@reduxjs/toolkit/node_modules/immer";

export const getAssessmentData = createAsyncThunk(
  "finalAssessmentQuiz/getAssessmentData",
  async (id) => {
    const response = await get(`/quiz/update-final-assessment/${id}/`).then(
      (resp) => {
        return resp.data;
      }
    );
    return response;
  }
);
// update quiz data
export const updateFinalAssessmentData = createAsyncThunk(
  "finalAssessmentQuiz/updateFinalAssessmentData",
  async (data) => {
    const response = await put(
      `/quiz/update-final-assessment/${data.id}/`,
      data.data
    ).then((resp) => {
      return resp.data;
    });
    return response;
  }
);
export const adminResQuizSlice = createSlice({
  name: "adminQuizSlice",
  initialState: {
    quizArr: [
      {
        question: "",
        image: "",
        points: 1,
        timer: 0,
        answers: [],
      },
    ],
  },
  reducers: {
    addAssessmentResQuiz: (state, { payload }) => {
      // if (state.quizArr[state.quizArr.length - 1].question === "") {
      const data = {
        question: "",
        image: "",
        points: 1,
        timer: 0,
        answers: [],
      };
      state.quizArr = [data];
      // }
    },
    deleteAssessmentQuestion: (state, { payload }) => {
      state.quizArr = state.quizArr.filter((item, i) => i !== payload);
    },
    clearAssessment: (state, { payload }) => {
      return (state = {
        quizArr: [
          {
            question: "",
            image: "",
            points: 1,
            timer: 0,
            answers: [],
          },
        ],
      });
    },
    editAssessmentOptionReducer: (state, { payload }) => {
      const nextstate = produce(current(state.quizArr), (draft) => {
        if (payload.keyName === "delete") {
          draft[payload.questionIndex].answers = draft[
            payload.questionIndex
          ].answers.filter((item, i) => i !== payload.optionIndex);
        } else if (
          payload.keyName === "question" ||
          payload.keyName === "image" ||
          payload.keyName === "points" ||
          payload.keyName === "timer"
        ) {
          draft[payload.questionIndex][payload.keyName] = payload.value;
        } else {
          draft[payload.questionIndex].answers[payload.optionIndex][
            payload.keyName
          ] = payload.value;
        }
      });
      return { ...state, quizArr: nextstate };
    },
    addAssessmentOptionReducer: (state, { payload }) => {
      const nextstate = produce(current(state.quizArr), (draft) => {
        draft[payload.questionIndex].answers.push(payload.value);
      });
      return { ...state, quizArr: nextstate };
    },
    addAssessmentQuestion: (state, { payload }) => {
      return { ...state, quizArr: [...state.quizArr, payload] };
    },
  },
  extraReducers: {
    [getAssessmentData.fulfilled]: (state, { payload }) => {
      const quizData = payload.reverse().map((item) => item.quiz);
      return { ...state, quizArr: quizData };
    },
    [updateFinalAssessmentData.fulfilled]: (state, { payload }) => {
      // return {...state,quizArr:[]}
      state = { quizArr: [] };
    },
  },
});
export const {
  addAssessmentResQuiz,
  deleteAssessmentQuestion,
  clearAssessment,
  editAssessmentOptionReducer,
  addAssessmentOptionReducer,
  addAssessmentQuestion,
} = adminResQuizSlice.actions;
export const getAdminQuizStore = (state) => state.adminQuizSlice;
export default adminResQuizSlice.reducer;
