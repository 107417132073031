import { createSlice,createAsyncThunk,current } from '@reduxjs/toolkit'
import {get,post,del, put} from '../../api/MainClient'
import produce from '@reduxjs/toolkit/node_modules/immer'
//==================course===============//
//  get faq data 
export const getFaqData= createAsyncThunk( 
    "faq/getFaqData",
    async (id)=>{
       const response=await get(`/update-course-faq/${id}/`).then((resp)=>{
           return resp.data
       })
       return response
    }
)
//  update faq data 
export const updateFaqData= createAsyncThunk( 
    "faq/updateFaqData",
    async (id,{getState})=>{
        const faqState=getState().faq.faqData
       const response=await put(`/update-course-faq/${id}/`,faqState).then((resp)=>{
           return resp.data
       })
       return response
    }
)
//=============webinar================//
export const getWebinarFaq= createAsyncThunk( 
    "faq/getWebinarFaq",
    async (id)=>{
       const response=await get(`/webinar/faq/update/${id}/`).then((resp)=>{
           return resp.data
       })
       return response
    }
)
//  update faq data 
export const updateWebinarFaq= createAsyncThunk( 
    "faq/updateWebinarFaq",
    async (id,{getState})=>{
        const faqState=getState().faq.faqData
       const response=await put(`/webinar/faq/update/${id}/`,faqState).then((resp)=>{
           return resp.data
       })
       return response
    }
)
export const faqSlice = createSlice({

    name: 'faq',
    initialState: {
      faqData:[

      ]
    },
  reducers: {   
  editFaqValues:(state,{payload})=>{
    const nextstate=produce(current(state.faqData),draft=>{
       draft[payload.faqIndex][payload.keyName]=payload.value
    })
    return {...state,faqData:nextstate}
        },
        deleteFaq:(state,{payload})=>{
        state.faqData= state.faqData.filter((item,i)=>i!==payload.faqIndex)

        },
        addNewFaq:(state,{payload})=>{
            if(state.faqData[state.faqData.length-1].question!==''){
                    const data={
                        question:'',
                        answer:''
                    }
                    state.faqData=[...state.faqData,data]
            }
        }
        },
  extraReducers:{
      [getFaqData.fulfilled]:(state,{payload})=>{
        console.log(payload);
        return {...state,faqData:payload}
      },
      [updateFaqData.fulfilled]:(state,{payload})=>{
          return {...state,faqData:[]}
      },
      [getWebinarFaq.fulfilled]:(state,{payload})=>{
        console.log(payload);
        return {...state,faqData:payload}
      },
      [updateWebinarFaq.fulfilled]:(state,{payload})=>{
        return {...state,faqData:[]}
    },
  }


})

export const {editFaqValues,deleteFaq,addNewFaq} = faqSlice.actions
export const getFaqStore=(state)=>state.faq
export default faqSlice.reducer


