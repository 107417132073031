import React from "react";
import "./Connection.css"
import Footer1 from "../../layout/footer/footer1";
import Connelose from "../../../images/Connectionerror/connection-lost.png"
import NewHeader from "../../layout/header/header1";
function ConnectionError() {
  return (
    <div className="Connnection-Lose">
      {/* <img src='' alt=''/> */}
      <NewHeader />
      <div className="Connection-Main-Div">
          <img  src={Connelose}></img>
        <h1>Connection Lost</h1>
        <p> Retry again</p>
      </div>

      <Footer1 />
    </div>
  );
}

export default ConnectionError;
