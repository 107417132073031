import { configureStore } from "@reduxjs/toolkit";
//redux persist
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
//reducers
import curriculumSlice from "./instructor/curriculumSlice";
import finalAssessmentSlice from "./instructor/finalAssessmentSlice";
import lessonQuizSlice from "./instructor/lessonQuizSlice";
import sidebarSlice from "./instructor/sidebarSlice";
import cartSlice from "./reducers/cart/cartSlice";
import courseSlice from "./reducers/course/courseSlice";
import filterSlice from "./reducers/course/filterSlice";
import modalSlice from "./reducers/modalSlice";
import testReducer from "./reducers/testSlice";
import webinarSlice from "./reducers/webinar/webinarSlice";
import faqSlice from "./instructor/faqSlice";
import assessmentSlice from "./reducers/Mylearning/studentAssessmentSlice";
import courseLearnSlice from "./reducers/courseLearn/courseLearnSlice";

import resquizSlice from "./reducers/Resources/resourceQuizSlice"
import adminResourceQuizSlice from "./admin/adminResourceQuizSlice";

const reducers = combineReducers({
  // test:testReducer,
  courses: courseSlice,
  filter: filterSlice,
  webinar: webinarSlice,
  cart: cartSlice,
  modal: modalSlice,
  sidebarState: sidebarSlice,
  curriculum: curriculumSlice,
  lessonQuiz: lessonQuizSlice,
  finalAssessmentQuiz: finalAssessmentSlice,
  faq: faqSlice,
  studentAssessment: assessmentSlice,
  resourceQuiz: resquizSlice,
  adminResourceQuiz:adminResourceQuizSlice,
  courseLearn: courseLearnSlice,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export default store;
