import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    addTocartPopup:false,
    login:false

}

const modalSlice = createSlice({
    name: "modal",
    initialState,
    reducers: {
    openAddToCart:(state,{payload})=>{
          state.addTocartPopup=payload
    }
    }
});

export const {openAddToCart} = modalSlice.actions
export const getAllModal=(state)=>state.modal
export default modalSlice.reducer