import { createAsyncThunk,createSlice } from '@reduxjs/toolkit'
import {get,post,del, put} from '../../api/MainClient'

export const getCourseModule= createAsyncThunk(
    "curriculum/getCourseModule",
    async (id)=>{
       const response=await get(`/list-module/${id}/`).then((resp)=>{
           return resp.data
       })
       return response
    }
)
// update module
export const addCourseModule= createAsyncThunk(
    "curriculum/addCourseModule",
    async (data,thunkAPI)=>{
       const response=await post(`/add-module/${data.courseID}/`,data.data).then((resp)=>{
        thunkAPI.dispatch(getCourseModule(data.courseID))
       }).catch((err)=>console.log('err',err))
      
    }
)
//delete module
export const deleteCourseModule= createAsyncThunk(
    "curriculum/deleteCourseModule",
    async (data,thunkAPI)=>{
       const response=await del(`/delete-module/${data.moduleID}/`).then((resp)=>{
        thunkAPI.dispatch(getCourseModule(data.courseID))
       }).catch((err)=>console.log('err',err))
      
    }
)
// update module
export const updateCourseModule= createAsyncThunk(
    "curriculum/updateCourseModule",
    async (data,thunkAPI)=>{
       const response=await put(`/update-module/${data.moduleID}/`,data.data).then((resp)=>{
        thunkAPI.dispatch(getCourseModule(data.courseID))
       }).catch((err)=>console.log('err',err))
      
    }
)
// delete lessons
export const deleteCourseLessons= createAsyncThunk(
    "curriculum/deleteCourseLessons",
    async (data,thunkAPI)=>{
       const response=await del(`/delete-lesson/${data.lessonID}/`).then((resp)=>{
        thunkAPI.dispatch(getCourseModule(data.courseID))
       }).catch((err)=>console.log('err',err))
      
    }
)
// add courseLessons
export const addCourseLessons= createAsyncThunk(
    "curriculum/addCourseLessons",
    async (data,thunkAPI)=>{
       const response=await post(`/add-lesson/${data.moduleID}/`,data.data).then((resp)=>{
        thunkAPI.dispatch(getCourseModule(data.courseID))
       }).catch((err)=>console.log('err',err))
      
    }
)
// course  lessons
export const updateCourseLessons= createAsyncThunk(
    "curriculum/updateCourseLessons",
    async (data,thunkAPI)=>{
       const response=await put(`/update-lesson/${data.lessonID}/`,data.data).then((resp)=>{
        thunkAPI.dispatch(getCourseModule(data.courseID))
       }).catch((err)=>console.log('err',err))
      
    }
)
// add lesson quiz
export const addLessonQuiz= createAsyncThunk(
    "curriculum/addLessonQuiz",
    async (data,thunkAPI)=>{
       const response=await post(`/quiz/add-lesson-quiz/`,data.data).then((resp)=>{
        thunkAPI.dispatch(getCourseModule(data.courseID))
       }).catch((err)=>console.log('err',err))
      
    }
)
// delete quiz
export const deleteLessonQuiz= createAsyncThunk(
    "curriculum/deleteLessonQuiz",
    async (data,thunkAPI)=>{
       const response=await del(`/quiz/update-lesson-quiz/${data.lessonID}/`).then((resp)=>{
        thunkAPI.dispatch(getCourseModule(data.courseID))
       }).catch((err)=>console.log('err',err))
      
    }
)
// update   lessons quiz
export const updateLessonQuiz= createAsyncThunk(
    "curriculum/updateLessonQuiz",
    async (data,thunkAPI)=>{
       const response=await put(`/quiz/update-lesson-quiz/${data.lessonID}/`,data.data).then((resp)=>{
        thunkAPI.dispatch(getCourseModule(data.courseID))
       }).catch((err)=>console.log('err',err))
      
    }
)
export const curriculumSlice = createSlice({
  name: 'curriculum',
  initialState: {
      toggleModule:false,
      toggleLesson:false,
      toggleLessonPreview:false,
       courseID:'',
       createModule:[
         
       ],
       createLesson:[

       ],
       modules:[
      {
          lessons:[

          ]
      }
  ]
  },
  reducers: {
       showCurriculumToggle:(state,{payload})=>{

        return {...state,[payload]:true}
       },
       closeCurriculumToggle:(state,{payload})=>{

        return {...state,[payload]:false}
       },
       CreateModuleData:(state,{payload})=>{
      state.createModule={...state.createModule,[payload.type]:payload.value}
       },
       CreateLessonData:(state,{payload})=>{
      return state.createLesson={...state.createLesson,payload}
         }
  },
  extraReducers:{
      [getCourseModule.fulfilled]:(state,{payload})=>{
          return {...state,modules:payload}
      },
  }
})

// Action creators are generated for each case reducer function
export const {showCurriculumToggle,closeCurriculumToggle,CreateLessonData} = curriculumSlice.actions
export const getCurriculumStore=(state)=>state.curriculum
export default curriculumSlice.reducer