import { createAsyncThunk, createSlice,current,original } from '@reduxjs/toolkit'
import produce from '@reduxjs/toolkit/node_modules/immer'
import {get} from '../../../api/MainClient'
//get webinarData
export const getWebinarData=createAsyncThunk(
    'webinar/getWebinarData',
    async (page)=>{
        console.log(page,"webinarSlice");
        const resp =await get(`/webinar/new-webinar-list/?page=${page?.page}&ordering=${page?.sorting}`)
        return resp.data
    }
)
// get course filter data

export const postWebinarFilterData=createAsyncThunk(
    "webinar/postWebinarFilterData",
    async (dataType,{getState})=>{
        const cors=await getState()
        console.log("cors data is ",cors);
        let categoryList=[]
        const activeType=cors.webinar.type?.find(item=>item.checked===true)
        const categoryArray=  cors.webinar.categories?.map(arr=>{
          if(arr.checked==true){
            categoryList.push(arr.id)
          }
        })
       const categoryString=categoryList?.toString()
       let languageList=[]
        const languageArray=cors.webinar.languages?.map(arr=>{
            if(arr.checked==true){
              languageList.push(arr.id)
            }
          })
        const languageString=languageList?.toString()
        let levelList=[]
        const levelsArray=cors.webinar.levels?.map(arr=>{
            if(arr.checked==true){
              levelList.push(arr.id)
            }
          })
        const levelString=levelList?.toString()
        const activeDuration=cors.webinar.duration?.find(item=>item.checked===true)
        const activePrice=cors.webinar.price?.find(item=>item.checked===true)
        // console.log(`webinar/main/?webinar_category=${categoryString}&language=${languageString}&webinar_type=${activeType&&activeType.id!==0?activeType.id:''}&session_type=&level=${levelString}&price_min=${activePrice!==undefined?activePrice.priceStart:''}&price_max=${activePrice!==undefined?activePrice.priceEnd:''}&timing=&duration_min=${activeDuration!==undefined?activeDuration.start:''}&duration_max=${activeDuration!==undefined?activeDuration.end:''}&sorted_by=`)
       
        const resp=await get(`/webinar/new-webinar-list/?category=${categoryString}&language=${languageString}&webinar_type=${activeType&&activeType.id!==0?activeType.id:''}&session_type=&level=${levelString}&price_min=${activePrice!==undefined?activePrice.priceStart:''}&price_max=${activePrice!==undefined?activePrice.priceEnd:''}&timing=&duration_min=${activeDuration!==undefined?activeDuration.start:''}&duration_max=${activeDuration!==undefined?activeDuration.end:''}&ordering=${cors?.webinar.listOrder==="POPULAR"?'popular':`${cors?.webinar.listOrder}`}&page=${cors?.webinar?.page}`)
        return resp.data
    }
)
export const filterAppliedJumpToPage=createAsyncThunk(
    'webinar/filterAppliedJumpToPage',
    async (pageNo,{getState})=>{
        const currentState=await getState().webinar
        console.log(currentState);
     const appliedUrl=currentState.prev?.split('api/').at(-1)||currentState.next?.split('api/').at(-1)
     const replacedUrlPageNo=appliedUrl.split('&page=')[1].at(0)
     const replacedUrl=appliedUrl.replace(`&page=${replacedUrlPageNo}`,`&page=${pageNo}`)
     const response=await get(replacedUrl)
     return response.data
    }
)
    export const RedirectToPageNo=createAsyncThunk(
        'webinar/RedirectToPageNo',
        async (path)=>{
        const SplitUrl=path.split('api/')
            const resp =await get(SplitUrl.at(-1))
            return resp.data
        }
    )
//get webinar types
export const getWebinarTypes=createAsyncThunk(
    "webinar/getWebinarTypes",
    async ()=>{
        const resp=await get('webinar/type/')
        return resp.data
    }
)
//get webinar categories
export const getWebinarCategories=createAsyncThunk(
    "webinar/getWebinarCategories",
    async ()=>{
        const resp=await get('course-category/')
        return resp.data
    }
)
// get webinar language
export const getWebinarLanguages=createAsyncThunk(
    "webinar/getWebinarLanguages",
    async ()=>{
        const resp=await get('course-language/')
        return resp.data
    }
)
// get webinar levels
export const getWebinarLevels=createAsyncThunk(
    "webinar/getWebinarLevels",
    async ()=>{
        const resp=await get('course-level/')
        return resp.data
    }
)
//webinar new to old 
export const webinarNewToOld=createAsyncThunk(
    "webinar/webinarNewToOld",
    async ()=>{
        const resp=await get('webinar/main/?webinar_category=-created_at')
        return resp.data
    }
)
//webinar new to old 
export const webinarOldToNew=createAsyncThunk(
    "webinar/webinarOldToNew",
    async ()=>{
        const resp=await get('webinar/main/?webinar_category=created_at')
        return resp.data
    }
)
const initialState ={
    count:1,
    status:true,
    webinarList:[],
    filterTypes:[],
    type:[],
    categories:[],
    languages:[],
    levels:[],
    page:1,
    duration:[
        {start:0,end:60,checked:false,id:1},
        {start:60,end:120,checked:false,id:2},
        {start:120,end:240,checked:false,id:3},
        {start:240,end:'',checked:false,id:4},
        

    ],
    price:[
        {priceStart:100,priceEnd:500,checked:false},
        {priceStart:500,priceEnd:1000,checked:false},
        {priceStart:1000,priceEnd:5000,checked:false},
        {priceStart:5000,priceEnd:'',checked:false},
    ],
    listOrder:"POPULAR"
}

const webinarSlice = createSlice({
    name:"webinar",
    initialState,
    reducers: {
        editFilterData:(state,{payload})=>{
            const {keyName,value}=payload
            if(keyName==="listOrder"){
                return {...state,[keyName]:value}
              }
              else{
            const nextstate=produce(current(state[keyName]),draft=>{
                if(payload.keyName==="duration"||payload.keyName==="price"||payload.keyName==="type"){
                    current(state[keyName]).map((item,i)=>{
                        if(i!==payload.index||item.checked===true){
                           draft[i].checked=false
                        }
                        else{
                            draft[i].checked=true
                        }
                    })
                }
                else{
                if(draft[payload.index].checked)
                draft[payload.index].checked=false
                else
                draft[payload.index].checked=true
                }
            })
          return {...state,[keyName]:nextstate}
        }
        },
        deleteFilterData:(state,{payload})=>{
            const {keyName,value}=payload
            const nextstate=produce(current(state[keyName]),draft=>{
                draft[payload.index].checked=false
            })
          return {...state,[keyName]:nextstate}
        },
        getAppliedFilter:(state,{payload})=>{
            const currentState=current(state)
          const currentData={type:currentState.type,categories:currentState.categories,languages:currentState.languages,
            levels:currentState.levels,duration:currentState.duration,price:currentState.price}
            let fillArr=[]
                  for (let value in currentData) {
                currentData[value].map((item,i)=>{
                    if(item.checked===true){
                        fillArr.push({...item,index:i,keyName:value})
                    }
                }) }
                return {...state,filterTypes:fillArr}
          },
          clearFilter:(state,action)=>{
            return {...state,filterTypes:[],
                duration:[
                    {start:0,end:60,checked:false,id:1},
                    {start:60,end:120,checked:false,id:2},
                    {start:120,end:240,checked:false,id:3},
                    {start:240,end:'',checked:false,id:4},
            
                ],
                price:[
                    {priceStart:100,priceEnd:500,checked:false},
                    {priceStart:500,priceEnd:1000,checked:false},
                    {priceStart:1000,priceEnd:5000,checked:false},
                    {priceStart:5000,priceEnd:'',checked:false},
                ],
                listOrder:'NEWTOOLD'
            }
        },
        changepages:(state,action)=>{
            if(action.payload.type==="INCREMENT"){
            return{...state,page:state.page+1}
            }
            else if(action.payload.type==="DECREMENT"){
            return{...state,page:state.page-1}
            }
            else if(action.payload.type==="SET"){
                return {...state,page:action.payload.value}
            }
         },
         onBannnerClick:(state,action)=>{
            if(action.payload.type === "CLOSE_BNR"){
                return {...state,showBan:false}
            }
            if(action.payload.type === "OPEN_BNR"){
                return {...state,showBan:true}
            }
    
            
               }
       
         
    },
    extraReducers:{
        // set all webinar data
        [getWebinarData.pending]:(state,action)=>{
            console.log("loading");
            return {...state,status:true}
        },
        [getWebinarData.fulfilled]:(state,{payload})=>{
            console.log("fulfilled",payload);
            // return {...state,list:payload}
            return {...state,status:false,webinarList:payload.results,count:Math.ceil(payload.count/12),prev:payload.previous,next:payload.next}
        },
        [getWebinarData.rejected]:(state,{payload})=>{
            console.log("rejected");
            return {...state,status:false}

        },
        ////////////////////////////////////
        //set webinar type
        [getWebinarTypes.fulfilled]:(state,{payload})=>{
            const editArr=payload.map((item)=>{
                return {...item,checked:false}
            })
            state.type=[{id:0,webinar_type:"All",checked:false},...editArr]
        },
        // set webinar category 
        [getWebinarCategories.fulfilled]:(state,{payload})=>{
            const editArr=payload.map((item)=>{
                return {...item,checked:false}
            })
            state.categories=[...editArr]
        },
        // set webinar languages
        [getWebinarLanguages.fulfilled]:(state,{payload})=>{
            const editArr=payload.map((item)=>{
                return {...item,checked:false}
            })
            state.languages=[...editArr]
        },
        // set webinar levels
        [getWebinarLevels.fulfilled]:(state,{payload})=>{
            const editArr=payload.map((item)=>{
                return {...item,checked:false}
            })
            state.levels=[...editArr]
        },
        // set webinar filtyer outputs
        [postWebinarFilterData.pending]:(state,{payload})=>{
             console.log('webinar filter is pending');
        },
        [postWebinarFilterData.fulfilled]:(state,{payload})=>{
            console.log('webinar filter is sucess ');
            console.log('filter applied',payload);
            if(payload.results.length===0){
                return {...state,webinarList:"NO_WEBINAR"}
                }
                else
                return {...state,webinarList:payload.results,count:Math.ceil(payload.count/12),prev:payload.previous,next:payload.next}
       },
       [postWebinarFilterData.rejected]:(state,{payload})=>{
           console.log('webinar filter is rejected');
           return {...state}
       },
       [webinarNewToOld.fulfilled]:(state,{payload})=>{
        return {...state,webinarList:payload.results}
       },
       [webinarOldToNew.fulfilled]:(state,{payload})=>{
        return {...state,webinarList:payload.results}
       },
       [RedirectToPageNo.fulfilled]:(state,{payload})=>{
        console.log(payload);
        return {...state,webinarList:payload.results,count:Math.ceil(payload.count/12),prev:payload.previous,next:payload.next}
        },
        [filterAppliedJumpToPage.fulfilled]:(state,{payload})=>{
        console.log(payload);
        return {...state,webinarList:payload.results,count:Math.ceil(payload.count/12),prev:payload.previous,next:payload.next}
        }
    }
});

export const {editFilterData,getAppliedFilter,clearFilter,deleteFilterData,changepages,onBannnerClick} = webinarSlice.actions
export const getAllWebinar=(state)=>state.webinar
export default webinarSlice.reducer