import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   list:[],
   filterTypes:{}
}

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
    filterData:(state,action)=>{
        state.list=action.payload.list
        //  state.filterTypes=action.payload.type
          const filterApplied=action.payload.type
          for (let value in filterApplied) {
            if (filterApplied[value] === null || filterApplied[value] === undefined
              ||filterApplied[value]===""||filterApplied[value].length===0||filterApplied[value]===0) {
              delete filterApplied[value];
            }
          }
         state.filterTypes=filterApplied
    },
    filterNewToOld:(state,action)=>{
      state.list=action.payload
    },
    filterOldToNew:(state,action)=>{
      state.list=action.payload
    }
    }
});

export const {filterData,filterNewToOld,filterOldToNew} = filterSlice.actions
export default filterSlice.reducer