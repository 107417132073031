import { useState, useEffect } from "react";
function useBodyScrollLock() {
  const bodyStyle = document.body.style;
  const [isLocked, SetIsLocked] = useState(false);
  const toggleLock = () => {
    SetIsLocked(!isLocked);
  };

  useEffect(() => {
    bodyStyle.overflowY = isLocked ? "hidden" : "auto";
  }, [isLocked, bodyStyle]);

  return [isLocked, toggleLock];
}

export default useBodyScrollLock;
