import { get, post, del, put } from "./MainClient";
import { Buffer } from "buffer";
// post user login data

export const postLoginData = async (data) => {
  const loginRes = await post("users/login/", data)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return loginRes;
};

export const postOneTapGoogle = async (data) => {
  const googleRes = await post("users/google/onetap/", data)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return googleRes;
};

export const postGoogleData = async (data) => {
  const googleRes = await post("users/dj-rest-auth/google/connect/", data)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return googleRes;
};
export const googleTapRedirect = (
  redirectUrl,
  clientId,
  returnUrl,
  plans,
  proid,
  category
) => {
  const stateData = Buffer.from(returnUrl).toString("base64");
  console.log(`The encrypted data is ${stateData}`);
  // const redirect =Buffer.from(redirect, 'base64').toString('ascii')
  // console.log(`The decrypted data is ${redirect}`)
  const subplan = Buffer.from(plans).toString("base64");
  const pid = Buffer.from(proid).toString("base64");
  const ctry = Buffer.from(category).toString("base64");
  console.log(`The encrypted data is ${subplan}`);
  const datastate = `redir:${stateData},pln:${subplan},proid:${pid},catagory:${ctry}`;

  const scope =
    "https://www.googleapis.com/auth/userinfo.email+https://www.googleapis.com/auth/userinfo.profile";
  const googleRes = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${redirectUrl}&prompt=consent&response_type=token&client_id=${clientId}&scope=${scope}&access_type=online&state=${datastate}`;
  return googleRes;
};
export const postResetPass = async (data) => {
  const resetRes = await post("/users/password-reset/", data)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return resetRes;
};

export const postSignupData = async (code, data) => {
  const signupRes = await post(`/users/register/?refer=${code}`, data)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return signupRes;
};

export const newpassword = async (data) => {
  const newpass = await post("/users/password-reset-confirm/", data)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return newpass;
};

// get course data //

export const getCourse = async () => {
  const course = await get("course/").then((resp) => {
    return resp.data;
  });
  return course;
};

// get related course based on not enrolled
export const getCertificateRelated = async (id) => {
  const course = await get(`certificate/course/list/?course=${id}`).then(
    (resp) => {
      return resp.data;
    }
  );
  return course;
};
export const getMycourse = async () => {
  const MyLearning = await get("enrolled/course/")
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return MyLearning;
};

export const getmywebinar = async () => {
  const MyLearning = await get("/enrolled/webinar/")
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return MyLearning;
};

//get specific course

export const getCourseById = async (id) => {
  const course = await get(`course/${id}/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return course;
};

//   get course category

export const getCourseCategory = async () => {
  const course = await get(`course-category/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return course;
};

//get course languages

export const getCourseLanguage = async () => {
  const languages = await get(`course-language/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return languages;
};
// get course type
export const getCourseType = async () => {
  const type = await get(`course-type/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return type;
};

// get course levels
export const getCourseLevels = async () => {
  const levels = await get(`course-level/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return levels;
};

// get course filter data

export const getCourseFilter = async (data) => {
  const filteredData = await get(
    `course/?type=${
      data.type !== "all" && data.type ? data.type : ""
    }&category=${
      data.categories.length !== 0 ? data.categories[0] : ""
    }&instructor=&price__gte=&price__lte=${
      data.price.length !== 0 ? data.price[0] : ""
    }&level=${data.levels.length !== 0 ? data.levels[0] : ""}&duration__lte=${
      data.duration.length !== 0 ? data.duration[0] : ""
    }&language=${data.languages.length !== 0 ? data.languages[0] : ""}`
  )
    .then((resp) => {
      console.log(resp);
      if (resp.data.length === 0) {
        return ["error"];
      } else return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return filteredData;
};

//   Get search data
export const getSearchData = async (key) => {
  const search = await get(`/webinar/product-filter/?search=${key}`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return search;
};

// course new to lod
export const courseNewToOld = async () => {
  const list = await get(`course/?ordering=-created_at`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return list;
};

// course old to new
export const courseOldToNew = async () => {
  const list = await get(`course/?ordering=created_at`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return list;
};

//get webinar category
export const getWebinarCategory = async () => {
  const list = await get(`webinar/category/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return list;
};
// delete cart course

export const deleteCourseFromCart = async (id) => {
  const list = await del(`cart/delete/${id}/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return list;
};

//move to save later

export const moveToSaveLater = async (data) => {
  const list = await post(`savelater/move/`, data)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return list;
};

// delete from save for later
export const deleteSaveForLater = async (id) => {
  const list = await del(`savelater/remove/${id}/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return list;
};

// add to cart

export const addToCart = async (data) => {
  const list = await post(`/cart/add/`, data)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return list;
};

//payment send data

export const sendPaymentData = async (data) => {
  const list = await post(`payments/request/`, data)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return list;
};
//payment send data cart
export const sendPaymentDataCart = async (data) => {
  const list = await post(`/payments/cart-payment/`, data)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return list;
};

// get from cart

export const getFromCart = async () => {
  const list = await get("cart/")
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return list;
};

// get from enrolled table

export const getFromEnrolled = async () => {
  const list = await get("enrolled/course/")
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return list;
};

// get from saved course

export const getFromSavedCourse = async () => {
  const list = await get("savelater/list/")
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return list;
};
// get webinar by id
export const getWebinarById = async (id) => {
  const webinar = await get(`webinar/main/${id}/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return webinar;
};

//  get webinar data for card

export const getWebinar = async () => {
  const webinar = await get(`webinar/main`).then((resp) => {
    return resp.data;
  });
  return webinar;
};

//   get webinar type
export const getWebinarType = async () => {
  const webinarType = await get(`/webinar/type/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return webinarType;
};

//   get webinar FAQ by webinar id

export const getWebinarFAQById = async (id) => {
  const webinar = await get(`webinar/FAQ/?webinar_id=${id}`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return webinar;
};

//   get webinar enrolled

export const getFromWebinarEnrolled = async () => {
  const list = await get("enrolled/webinar/")
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return list;
};

// get webinarcart
export const getFromWebinarCart = async () => {
  const list = await get("cart/webinar-list/")
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return list;
};
export const addWebinarToCart = async (data) => {
  const list = await post(`cart/webinar-add/`, data)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return list;
};

// add webinar to save later

export const moveWebinarToSaveLater = async (data) => {
  const list = await post(`savelater/move/`, data)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return list;
};

// delete webinar from scart

export const deleteWebinarFromCart = async (id) => {
  const list = await del(`cart/webinar-delete/${id}/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return list;
};
// get course details
export const getCourseAbout = async (id) => {
  const data = await get(`course-details/${id}/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return data;
};

//get course modules
export const getCourseModules = async (id) => {
  const data = await get(`course-syllabus/${id}/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return data;
};
// get user profile
export const getuserprofile = async () => {
  const getuser = await get("/users/user-profile/")
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return getuser;
};

//   get webinar details
export const getWebinardetails = async (id) => {
  const webinardetails = await get(`/webinar/detail/${id}/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return webinardetails;
};

//   get webinar faq list
export const getWebinarfaq = async (id) => {
  const webinarfaq = await get(`/webinar/faq/list/${id}/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return webinarfaq;
};

//   get course faq list
export const getCoursefaq = async (id) => {
  const coursefaq = await get(`/list-faq/${id}/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return coursefaq;
};

//   get course completed
export const getcompletedCourse = async () => {
  const completed = await get(`/course-completed/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return completed;
};

//   post free course
export const freecourse = async (data) => {
  const free = await post(`/enrolled/free-course/`, data)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return free;
};

// enrolled webinar
export const getenrolledwebinar = async () => {
  const enrollwebinar = await get(`/enrolled/webinar/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return enrollwebinar;
};

// Post free webinar
export const freewebinar = async (data) => {
  const free = await post(`/enrolled/free-webinar/`, data)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return free;
};

//   get webinar content
export const getAllContent = async (id) => {
  const content = await get(`/webinar/content-resource/list/${id}/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return content;
};
//submit course final assessment
export const submitAssessment = async (data) => {
  const free = await post(`/quiz/final-assessment-response-add/`, data).then(
    (resp) => {
      return resp.data;
    }
  );
  return free;
};
//   get course assessment response
export const getAssessmentResult = async (id) => {
  const content = await get(`/quiz/final-assessment-result/?course=${id}`).then(
    (resp) => {
      return resp.data;
    }
  );
  return content;
};
//   get course final assessment score
export const getAssessmentScore = async (id) => {
  const content = await get(`/quiz/final-assessment-score/?course=${id}`).then(
    (resp) => {
      return resp.data;
    }
  );
  return content;
};
//   get course final assessment  limit data
export const getAssessmentLimit = async (id) => {
  const content = await get(`/quiz/final-assessment-detail/${id}/`).then(
    (resp) => {
      return resp.data;
    }
  );
  return content;
};

//get testimonial
export const getcourseTestimonial = async (id) => {
  const testimonial = await get(`/student/reviews/${id}/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return testimonial;
};
//get course testimonial list -student Side
export const getwebinarTestimoniallist = async (
  id,
  page,
  items,
  sort,
  filter
) => {
  const testimonials = await get(
    `/webinar/webinar-review/list/${id}/?page=${page}&itemperpage=${items}${
      sort !== "" || sort !== undefined ? sort : null
    }${filter !== "" || filter !== undefined ? filter : null}`
  )
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return testimonials;
};

export const getcourseTestimoniallist = async (
  id,
  page,
  items,
  sort,
  filter
) => {
  // /students/reviews/list/${id}/

  const testimonial = await get(
    `/students/reviews/list/${id}/?page=${page}&itemperpage=${items}${
      sort !== "" || sort !== undefined ? sort : null
    }${filter !== "" || filter !== undefined ? filter : null}`
  )
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return testimonial;
};

// get testimonial review
export const getcourseReview = async (id) => {
  const testimonialss = await get(`/instructor/reviews/${id}/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return testimonialss;
};

// get related course
export const getRelatedcourse = async (id) => {
  const relatedcourse = await get(`/related-courses/${id}/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return relatedcourse;
};

// get related webinar

export const getRelatedwebinar = async (id) => {
  const relatedwebinar = await get(`/webinar/related-webinars/list/${id}/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return relatedwebinar;
};

// Update  instructor profile

export const Editprofile = async (data) => {
  const profile = await put(`/dashboard/instructor/info/update/`, data)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return profile;
};

// get instructor profile

export const getprofile = async () => {
  const profile = await get(`/dashboard/instructor/info/update/`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return profile;
};

// get instructor Earning

export const getinstructorearning = async () => {
  const earning = await get(`/dashboard/instructor/earning/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return earning;
};
// get certificateData
export const getCertificateData = async (id) => {
  const data = await get(`/quiz/certificate/?course=${id}`).then((resp) => {
    return resp.data;
  });
  return data;
};

// New Api for get certificate 

export const getCertificateData2 = async (id) => {
  const data = await get(`/quiz/certificate/view/?certificate_number=${id}`).then((resp) => {
    return resp.data;
  });
  return data;
};

// update user profile
export const updateuserprofile = async (data) => {
  const profile = await put(`/users/user-profile/`, data)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return profile;
};

// Add Account details - instructor side
export const AddAccount = async (data) => {
  const account = await post(
    `/dashboard/instructor/account-details/`,
    data
  ).then((resp) => {
    return resp;
  });
  return account;
};

// get Account details

export const getAccountdetail = async () => {
  const account = await get(`/dashboard/instructor/account-details-update/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return account;
};
// Update Account details
export const putuserprofile = async (data) => {
  const profile = await put(
    `/dashboard/instructor/account-details-update/`,
    data
  )
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return profile;
};

// get my products
export const getProductcreation = async () => {
  const account = await get(`/dashboard/instructor/product-creation`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return account;
};
// get homepage feeedback api
export const getFeedback = async () => {
  const data = await get(`/users/review/list/`).then((resp) => {
    return resp.data;
  });
  return data;
};
// Top instructor Api
export const Topinstructor = async () => {
  const Top = await get(`/users/top-instructors/list/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return Top;
};

export const homepagefaq = async () => {
  const faq = await get(`/company/faq/main/list/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return faq;
};

// ======explore Api================>>>

export const Investing = async () => {
  const Invest = await get(`/webinar/category-explorer/?search=1`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return Invest;
};

// completed lesson
export const completedlesson = async (data) => {
  const complete = await post(`/lesson-completed/`, data)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return complete;
};

export const Trading = async () => {
  const Trade = await get(`/webinar/category-explorer/?search=2`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return Trade;
};

export const ShareMarketing = async () => {
  const Share = await get(`/webinar/category-explorer/?search=3`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return Share;
};
/*trending course */
export const Trending = async () => {
  const Trend = await get("/homepage/trending/course-webinar/")
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return Trend;
};

// Add webinar comment
export const webinarcmt = async (id, data) => {
  const comment = await post(`/webinar/discussion-comment/add/${id}/`, data)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return comment;
};

// get comment
export const getcomment = async (id) => {
  const getcmt = await get(`/webinar/discussion-comment/list/${id}/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return getcmt;
};
//Get Like Foor Comment

// Add like to comment reply

export const webinarlike = async (id) => {
  const like = await post(`/webinar/discussion-comment-like/add/${id}/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return like;
};
// Add webinar comment reply
export const webinarrply = async (id, data) => {
  const reply = await post(`/webinar/discussion-comment-reply/add/${id}/`, data)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return reply;
};

// get reply from comments

export const getreply = async (id) => {
  const reply = await get(`/webinar/discussion-comment-reply/list/${id}/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return reply;
};
// Add reply like
export const rplylike = async (id) => {
  const reply = await post(`/webinar/discussion-comment-reply-like/add/${id}/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return reply;
};
//get the
export const getWebinrNote = async (id) => {
  const getcmt = await get(`/webinar/notes/list/${id}/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return getcmt;
};
// Add Webinar notes

export const webinarNotes = async (id, data) => {
  const notes = await post(`/webinar/notes/add/${id}/`, data)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return notes;
};

//update the webinar note
export const Updatewebinarnote = async (id, data) => {
  const updatenotes = await put(`/webinar/notes/update/${id}/`, data)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return updatenotes;
};

// Contact us

export const Contactus = async (data) => {
  const contact = await post("/contacts/", data)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return contact;
};

//get the popular course
export const getPopularcourse = async () => {
  const getpopCourse = await get(`/popular-course/homepage/list/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return getpopCourse;
};

export const getPopularwebinar = async () => {
  const getpopWeb = await get(`/webinar/popular-webinar/homepage/list/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return getpopWeb;
};

//get explore data

export const getExploreDatas = async () => {
  const getExpo = await get(`/explore/`).then((resp) => {
    return resp.data;
  });
  return getExpo;
};

export const getExportcategory = async (data) => {
  const getExpo1 = await get(`/category-explorer/?search=${data}`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return getExpo1;
};

export const inprogress = async (data) => {
  const allprogress = await get(`/enrolled/list/`)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      return error.response;
    });
  return allprogress;
};
// post lesson completed
export const postLessonCompleted = async (data) => {
  const lessonSatus = await post("/lesson-completed/", data)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return lessonSatus;
};

// get lesson data
export const getCourseLesson = async (id) => {
  const lesson = await get(`lesson/${id}/`).then((resp) => {
    return resp.data;
  });
  return lesson;
};

export const getCertificateStatus = async (id) => {
  const lesson = await get(`/module-completed-status/?course=${id}`).then(
    (resp) => {
      return resp.data;
    }
  );
  return lesson;
};

// post course Feedback
export const coursefdback = async (id, data) => {
  const feedback = await post(`/rating/add/${id}/`, data)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};
// get course Feedback
export const Getcoursefdback = async (id) => {
  if(id === undefined){
    return 
  }
  const feedback = await get(`/rating/add/${id}/`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};

// post webinar Feedback
export const webinarfdback = async (id, data) => {
  const feedback = await post(`/webinar/rating/add/${id}/`, data)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};

// get webinar Feedback
export const getWebinarFeedback = async (id) => {
  const feedback = await get(`webinar/rating/add/${id}/`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};

//post coursenotes
export const postCourseNoteData = async (data) => {
  const notes = await post("lesson-note/", data).then((resp) => {
    return resp.data;
  });
  return notes;
};
// get user notes
export const getCourseNoteData = async (id, user) => {
  const notes = await get(`lesson-note/?lesson=${id}&user=${user}`).then(
    (resp) => {
      return resp.data;
    }
  );
  return notes;
};
//   put course lesson notes
export const putCourseNoteData = async (id, data) => {
  const notes = await put(`lesson-note/${id}/`, data).then((resp) => {
    return resp.data;
  });
  return notes;
};

/*ADD WEBINAR COMPLETED */
export const webinarcompleted = async (id) => {
  const complete = await post(`/webinar/add-webinar-completed/${id}/`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return complete;
};

// GET COMPLETED WEBINAR

export const completewebinarget = async (id) => {
  const webcomplete = await get(`/webinar/webinar-completed/`).then((resp) => {
    return resp.data;
  });
  return webcomplete;
};

//Faq categories List

export const faqcategories = async () => {
  const faqcatelist = await get(`/contacts/faq/categories/`).then((resp) => {
    return resp.data;
  });
  return faqcatelist;
};

//get the faq categorie data

export const faqcategoriesData = async (id) => {
  const faqcatedata = await get(`/contacts/faq/category/issues/${id}/`).then(
    (resp) => {
      return resp.data;
    }
  );
  return faqcatedata;
};
export const postEmailSubscribe = async (email) => {
  const endPoint = await post(`/marketing/subscribe/?email=${email}`).then(
    (resp) => {
      return resp;
    }
  );
  return endPoint;
};
//Final Assessment Comfirm
export const assessmentConfirm = async (course) => {
  const endPoint = await put(
    `/quiz/final-assessment-response/update/`,
    course
  ).then((resp) => {
    return resp;
  });
  return endPoint;
};

// update Course-review
export const updateCourseReview = async (id, data) => {
  if(id === undefined) {
    return
  }
  const endPoint = await put(`/rating/update/${id}/`, data).then((resp) => {
    return resp;
  });
  return endPoint;
};

// update Webinar-review
export const updateWebinarReview = async (id, data) => {
  if(id === undefined){
    return 
  }
  const endPoint = await put(`/webinar/rating/update/${id}/`, data).then(
    (resp) => {
      return resp;
    }
  );
  return endPoint;
};

//faq subscribe

export const SubscribitionFaq = async () => {
  const subFaq = await get(`/contacts/faq/category/issues/9/`).then((resp) => {
    return resp.data;
  });
  return subFaq;
};

// Subscribe

export const Subscribenow = async (data) => {
  const sub = await post("/users/subscribe/", data).then((resp) => {
    return resp;
  });
  return sub;
};

// plans for Subscribe

export const SubscribitionPlans = async () => {
  const plans = await get(`/users/subscription/plan/list/`).then((resp) => {
    return resp.data;
  });
  return plans;
};

// Active plan

export const Activeplan = async () => {
  const getplan = await get(`/users/subscription/details/`)
    .then((resp) => {
      return resp;
    })
    .catch((err) => console.log(err));
  return getplan;
};

export const Purchasedwebinar = async () => {
  const purchase = await get(`/enrolled/purchased/list/?type=webinar`)
    .then((resp) => {
      return resp;
    })
    .catch((err) => console.log(err));
  return purchase;
};

export const Purchasedcourse = async () => {
  const purchase = await get(`/enrolled/purchased/list/?type=course`)
    .then((resp) => {
      return resp;
    })
    .catch((err) => console.log(err));
  return purchase;
};

export const Affiligoogle = async (data) => {
  const affigoogle = await post("/affiliate/add-affiliate-user/", data)
    .then((resp) => {
      return resp;
    })
    .catch((err) => console.log(err.response));
  return affigoogle;
};

export const coursePagination = async (page) => {
  const sub = await get(`course/?page=${page}`).then((resp) => {
    return resp.data;
  });
  return sub;
};

export const cartCuponList = async () => {
  const sub = await get(`/cart/cart-view`).then((resp) => {
    return resp.data;
  });
  return sub;
};

export const cartCuponValidInput = async (selectedCoupon,code) => {
  const sub = await get(
    `/cart/coupon-validation?coupon_code=${selectedCoupon}&code=${code}`
  ).then((resp) => {
    return resp.data;
  });
  return sub;
};

export const partnerUserCourseView = async (selectedCoupon) => {
  const sub = await get(`/partner/course-view`).then((resp) => {
    return resp.data;
  });
  return sub;
};

export const faqUserPartnerPageData = async (id) => {
  const faqcatedata = await get(`/contacts/faq/category/issues/10/`).then(
    (resp) => {
      return resp.data;
    }
  );
  return faqcatedata;
};

/*resource use side api start */

export const postResourcesDownloadCount = async (data) => {
  const feedback = await post(`/resources/add-download/`, data)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};

export const getResourcesRelatedCourse = async (id) => {
  const feedback = await get(`/resources/related-courses/${id}/`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};
export const getResourcesRelatedWebinar = async (id) => {
  const feedback = await get(`/resources/related-webinars/${id}/`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};

export const getUserEbookList = async (searchKey, filt) => {
  const feedback = await get(
    `/resources/ebooks/?search=${searchKey}&order=${filt}`
  )
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};

export const getUserEbookView = async (id) => {
  const feedback = await get(`/resources/ebook/${id}/`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};

export const getUserShortVideoList = async (searchKey) => {
  const feedback = await get(`/resources/short-videos/?search=${searchKey}`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};

export const getUserShortVideoView = async (id) => {
  const feedback = await get(`/resources/short-video/${id}/`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};

export const getUserDownloadableList = async (searchKey, filt) => {
  const feedback = await get(
    `/resources/downloadable-resources/?search=${searchKey}&order=${filt}`
  )
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};
export const getUserDownloadableView = async (id) => {
  const feedback = await get(`resources/downloadable-resource/${id}/`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};

export const getUserGlossaryList = async (searchKey) => {
  const feedback = await get(`/resources/glossarys/?search=${searchKey}`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};
export const getUserGlossaryView = async (id) => {
  const feedback = await get(`/resources/glossary/${id}/`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};

/*******quiz section ********** */
export const getUserQuizzesList = async (searchKey) => {
  const feedback = await get(`/resources/quizzes/?search=${searchKey}`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};

export const getUserQuizInstruction = async (id) => {
  const feedback = await get(`/resources/quiz-instructions/${id}/`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};

export const getUserQuizGuestInstruction = async (id) => {
  const feedback = await get(`/resources/quiz-instructions-guest/${id}/`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};
export const getUserQuizDeleteResponse = async (id) => {
  const feedback = await get(`/resources/quiz-delete-response/${id}/`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};

export const getUserQuizQuestion = async (id) => {
  const feedback = await get(`/resources/quiz/${id}/`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};

export const postUserQuizAnser = async (data) => {
  const feedback = await post(`/resources/quiz-response/`, data)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};

export const postGuestUserQuizAnser = async (data) => {
  const feedback = await post(`/resources/quiz-response-guest/`, data)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};

export const getUserQuizResult = async (id) => {
  const feedback = await get(`/resources/quiz-results/${id}/`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};
export const getUserQuizScore = async (id) => {
  const feedback = await get(`/resources/quiz-score/${id}/`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};

export const postEndQuiz = async (data) => {
  const feedback = await post(`/resources/end-quiz/`, data)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};

export const getQuizRelCourse = async (id) => {
  const feedback = await get(`/resources/quiz-related-courses/${id}/`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};
export const getQuizRelWebi = async (id) => {
  const feedback = await get(`/resources/quiz-related-webinar/${id}/`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};

export const  decemberevent = async (id) => {
  const december = await get(`/users/dec/`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return december;
};


/***blog api ***** */

export const getBlogListApi = async () => {
  const feedback = await get(`https://blog.joinfingrad.com/wp-json/wp/v2/posts`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};


/*home page blog api*/

export const getHomeBlogListApi = async () => {
  const feedback = await get(`https://blog.joinfingrad.com/wp-json/wp/v2/posts?categories=12`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};





/*header blog drop down*/
export const getBlogCat1ListApi = async () => {
  const feedback = await get(`https://blog.joinfingrad.com/wp-json/wp/v2/posts?categories=17`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};
export const getBlogCat2ListApi = async () => {
  const feedback = await get(`https://blog.joinfingrad.com/wp-json/wp/v2/posts?categories=35`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};
export const getBlogCat3ListApi = async () => {
  const feedback = await get(`https://blog.joinfingrad.com/wp-json/wp/v2/posts?categories=22`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};
export const getBlogCat4ListApi = async () => {
  const feedback = await get(`https://blog.joinfingrad.com/wp-json/wp/v2/posts?categories=31`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return feedback;
};




/*resource use side api end */

export const Postbookcall = async (data) => {
  const callbook = await post(`/contacts/consultation-session/`, data)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response;
    });
  return callbook;
};
