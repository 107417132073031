import { createSlice,createAsyncThunk,current } from '@reduxjs/toolkit'
import {get,post,del, put} from '../../api/MainClient'
import produce from '@reduxjs/toolkit/node_modules/immer'

//  get quiz data 
export const getQuizData= createAsyncThunk( 
    "quiz/getQuizData",
    async (id)=>{
       const response=await get(`/quiz/update-lesson-quiz/${id}/`).then((resp)=>{
           return resp.data
       })
       return response
    }
)
// update quiz data
export const updateQuizData= createAsyncThunk( 
    "quiz/updateQuizData",
    async (data)=>{
       const response=await put(`/quiz/update-lesson-quiz/${data.lessonID}/`,data.value).then((resp)=>{
           return resp.data
       })
       return response
    }
)
// delete quiz data 
export const deleteQuizAPI= createAsyncThunk( 
    "quiz/deleteQuizAPI",
    async (data,thunkAPI)=>{
       const response=await del(`/quiz/delete-lesson-quiz-question/${data.questionID}/`).then((resp)=>{
        thunkAPI.dispatch(getQuizData(data.lessonID))
       })
    }
)
export const lessonQuizSlice = createSlice({
  name: 'lessonQuiz',
  initialState: {
    quizArr:
      [
            {
                question: "",
                image: "",
                answers: [
                    
                ]
            }
        ]
  },
  reducers: {   
   addQuiz:(state,{payload})=>{
    if(state.quizArr[state.quizArr.length-1].question!==''){
       const data= {
            question: "",
            image: "",
            answers: [
                
            ]
        }
    
       state.quizArr=[...state.quizArr,data]
}
   },
   deleteQuestion:(state,{payload})=>{
   state.quizArr= state.quizArr.filter((item,i)=>i!==payload)
   },
   clearQuizData:(state,{payload})=>{
       state.quizArr=[
        {
            question: "",
            image: "",
            answers: [
                
            ]
        }
       ]
   },
   editOptionReducer:(state,{payload})=>{
    const nextstate=produce(current(state.quizArr),draft=>{
    if(payload.keyName==='delete'){
        draft[payload.questionIndex].answers=draft[payload.questionIndex].answers.filter((item,i)=>i!==payload.optionIndex)
    }
    else if(payload.keyName==='question'||payload.keyName==='image'){
     draft[payload.questionIndex][payload.keyName]=payload.value
    }
    else{
     draft[payload.questionIndex].answers[payload.optionIndex][payload.keyName]=payload.value
    }
    })
   return {...state,quizArr:nextstate}
   },
   addOptionReducer:(state,{payload})=>{
     const nextstate=produce(current(state.quizArr),draft=>{
            draft[payload.questionIndex].answers.push(payload.value)
     })
   return {...state,quizArr:nextstate}
   },
   addNewQuestion:(state,{payload})=>{
       return {...state,quizArr:[...state.quizArr,payload]}
   }
  
  },
  extraReducers:{
      [getQuizData.fulfilled]:(state,{payload})=>{
          console.log(payload);
        //  payload.pop()
          const quizArr=payload.map((item)=>item.quiz)
          state.quizArr=quizArr.reverse()
      },
      [updateQuizData.fulfilled]:(state,{payload})=>{
          return {...state,quizArr:[]}
      }
  }
})

// Action creators are generated for each case reducer function
export const {addQuiz,deleteQuestion,clearQuizData,editOptionReducer,addOptionReducer,addNewQuestion} = lessonQuizSlice.actions
export const getLessonQuizStore=(state)=>state.lessonQuiz
export default lessonQuizSlice.reducer