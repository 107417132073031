import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Activeplan } from "../../../api/fetchClient";
import { getActivePlan } from "../../../app/reducers/Freebtn/FreeButtonSlice";
import { useDispatch } from "react-redux";
function FreeBtn({ onUpFreeBtnlink, onHamburgerClick }) {
  const userString = localStorage.getItem("user_data");
  const user = JSON.parse(userString);
  const [isLoading, setIsLoading] = useState(false);
  const Subscribtion = JSON.parse(window.localStorage.getItem("Subscription"));

  const [plandetails, setPlandetails] = useState();
  const getPlan = async () => {
    const resp = await Activeplan();
    setPlandetails(resp?.data);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getPlan();
  }, []);

  // if (isLoading) {
  //     return <li className="header-nav-hov">
  //         <div className="subscribe-placeholder">
  //     </div>
  //     </li>
  // }

  if (!user) {
    return (
      <li><Link to="/signup"
        // onClick={() => {
        //   onUpFreeBtnlink();
        //   onHamburgerClick();
        // }}
        className="header-nav-hov cursor-pointer"
      >
        {/* <Link to="/subscribe"> */}
        <div className="subscribe-bac">
          <div>Signup</div>
        </div>
        {/* </Link> */}
      </Link></li>
    );
  }

  // if (
  //   Subscribtion?.plan === "Free" &&
  //   Subscribtion?.is_freetrial_used &&
  //   user
  // ) {
  //   return (
  //     <li
  //       onClick={() => {
  //         onUpFreeBtnlink();
  //         onHamburgerClick();
  //       }}
  //       className="header-nav-hov cursor-pointer"
  //     >
  //       {/* <Link to="/subscribe"> */}
  //       <div className="subscribe-bac">
  //         <div>Upgrade Plan</div>
  //       </div>
  //       {/* </Link> */}
  //     </li>
  //   );
  // } else if (Subscribtion === "" || Subscribtion === undefined) {
  //   return (
  //     <li
  //       onClick={() => {
  //         onUpFreeBtnlink();
  //         onHamburgerClick();
  //       }}
  //       className="header-nav-hov cursor-pointer"
  //     >
  //       {/* <Link to="/subscribe"> */}
  //       <div className="subscribe-bac">
  //         <div>Try For Free</div>
  //       </div>
  //       {/* </Link> */}
  //     </li>
  //   );
  // } else {
  //   return null;
  // }

  return null;
}

export default FreeBtn;
