import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Affiliateget } from "../../../Affiliate/Api/AffiliateClient";
import "./footer.css";
import Facebook from "../../../images/socialmedia/facebook.svg";
import Youtube from "../../../images/socialmedia/youtube.svg";
import LinkedIn from "../../../images/socialmedia/linked in.svg";
import Twitter from "../../../images/socialmedia/twitter.svg";
import Instagram from "../../../images/socialmedia/Instagram.svg";
// Images
import logo3 from "../../../images/Fingrad-fnl.png";

function Footer1() {
  const [verify, setverify] = useState();

  useEffect(() => {
    const getdata = async () => {
      const data = await Affiliateget().then((resp) => {
        setverify(resp.data);
      });
    };
    getdata();
  }, []);
  return (
    <>
      <footer className="footernew">
        <div className="ft-top">
          <Link to="/">
            <img src={logo3} alt="" />
          </Link>
        </div>
        <div className="d-flex justify-content-between foo-col">
          <span className="ft-txts ml-md-4">
            An initiative by{" "}
            <a
              style={{ color: "#007A78" }}
              href="https://tradebrains.in/"
              target="_blank"
            >
              tradebrains{" "}
            </a>
          </span>
          <div className="foo-right ">
            {/* <Link to="/coming-soon">
              <span className="ft-txt">Become An Instructor</span>
            </Link> */}
            {verify?.is_verified === "accepted" ? (
              ""
            ) : (
              <Link
                to="/become-affiliate"
                onClick={() => window.scrollTo(0, 0)}
              >
                <span className="ft-txt">Affiliate</span>
              </Link>
            )}

            <a href="https://discuss.joinfingrad.com/">
              <span className="ft-txt"> Forum</span>
            </a>

            <Link
              to="/instructor-details/"
              onClick={() => window.scrollTo(0, 0)}
            >
              <span className="ft-txt">Instructors</span>
            </Link>
            <a href="https://blog.joinfingrad.com/">
              <span className="ft-txt">Blog</span>
            </a>
            <Link to="/Contact" onClick={() => window.scrollTo(0, 0)}>
              <span className="ft-txt">Contact</span>
            </Link>

            <Link to="/about-us" onClick={() => window.scrollTo(0, 0)}>
              <span className="ft-txt">About</span>
            </Link>

            <Link to="/faq-section" onClick={() => window.scrollTo(0, 0)}>
              <span className="ft-txt">FAQ</span>
            </Link>
          </div>
        </div>
        <hr
          style={{
            border: "0.25px solid #007A78",
            margin: "0px",
            marginTop: "10px",
          }}
        />
        <div className="d-flex justify-content-between flex-column flex-lg-row w-100 align-items-center">
          <div className="d-flex align-items-center justify-content-center mx-auto mx-xl-0 footer-terms-condi-sec">
            <Link to="/terms-and-conditions">
              <span className="ft-botm">Terms of Use</span>
            </Link>
            <Link to="/privacy-policy">
              <span className="ft-botm"> Privacy Policy</span>
            </Link>
            <Link to="/refund-policy">
              <span className="ft-botm">Refund Policy</span>
            </Link>
            <Link to="/disclaimer">
              <span className="ft-botm">Disclaimer</span>
            </Link>
          </div>

          <ul className=" d-flex  ft-icon mr-md-5 mt-4 footer-social-icon-sec">
            <li className="foo-soc-hov">
              <a
                href="https://www.linkedin.com/company/joinfingrad/"
                rel="noopener noreferrer"
                target="_blank"
                className="btn-link  ml-3"
              >
                <img src={LinkedIn} alt="LinkedIn" />
              </a>
            </li>

            <li className="foo-soc-hov">
              <a
                href="https://twitter.com/joinfingrad"
                rel="noopener noreferrer"
                target="_blank"
                className="btn-link ml-3 mr-md-2"
              >
                <img src={Twitter} alt="Twitter" />
              </a>
            </li>

            <li className="foo-soc-hov">
              <a
                href="https://www.instagram.com/joinfingrad/"
                rel="noopener noreferrer"
                target="_blank"
                className="btn-link instagran-lnk"
              >
                <img src={Instagram} alt="instagram" />
              </a>
            </li>
            <li className="foo-soc-hov">
              <a
                href="https://www.facebook.com/joinfingrad/"
                rel="noopener noreferrer"
                target="_blank"
                className="btn-link"
              >
                <img src={Facebook} alt="facebook" />
              </a>
            </li>

            <li className="foo-soc-hov">
              <a
                href="https://www.youtube.com/channel/UCJo7PhLUmNo4Sq0VKT3ds9A"
                rel="noopener noreferrer"
                target="_blank"
                className="btn-link youtube-lnk"
              >
                <img src={Youtube} alt="Youtube" />
              </a>
            </li>
          </ul>

          <div className="d-flex flex-column flex-md-row mx-auto  mx-md-0 justify-content-center justify-content-md-end  ">
            <p style={{ marginBottom: "0px" }} className="ft-botm2">
              @ 2022 Dailyraven Technologies Pvt Ltd.
            </p>
            <p style={{ marginBottom: "0px" }} className="ft-botm2 mx-auto">
              All rights Reserved
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer1;
