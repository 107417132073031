import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { get, post, del, put } from "../../../api/MainClient";

export const getActivePlan = createAsyncThunk(
  "FreeBtnHeader/FreeBtnHeader",
  async () => {
    const resp = await get(`/users/subscription/details/`);
    return resp.data;
  }
);

const FreeBtnSlice = createSlice({
  name: "FreeBtnHeader",
  initialState: {
    freeBtnStatus: [],
  },
  reducers: {
    incrementQuestions: (state, { payload }) => {
      return { freeBtnStatus: state };
    },
  },
});
export const { incrementQuestions } = FreeBtnSlice.actions;
export const getFreeBtuutonStatusData = (state) => state.FreeBtnSlice;

export default FreeBtnSlice.reducer;
